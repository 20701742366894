<template>
  <section class="campaigns-table-wrap">
    <empty-state v-if="showEmptyResult" :title="emptyMessage" />
    <ve-table
      v-else
      :columns="columns"
      scroll-width="155rem"
      :table-data="tableData"
      :border-around="true"
    />
    <div v-if="isLoading" class="campaign--loader">
      <spinner />
      <span>{{ $t('CAMPAIGN.LIST.LOADING_MESSAGE') }}</span>
    </div>
    <woot-modal :show.sync="showOneOffEditPopup" :on-close="hideOneOffEditPopup">
      <edit-one-off-campaign
        :selected-campaign="selectedCampaign"
        @on-close="hideOneOffEditPopup"
      />
    </woot-modal>

    <woot-modal :show.sync="openProgressPopUp" :on-close="hideProgressPopup">
      <ProgressPopUp
        :show="openProgressPopUp"
        :payload="progressPayload"
        @on-close="hideProgressPopup"
        @show-more="showCampaignRecords"
      />
    </woot-modal>

    <woot-modal :show.sync="openCampaignRecords" :on-close="hideRecordsPopup">
      <campaign-records-modal
        :payload="recordsPayload"
        @on-close="hideRecordsPopup"
      />
    </woot-modal>


  </section>
</template>

<script>
import Vue from 'vue';
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import Label from 'dashboard/components/ui/Label';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import WootButton from 'dashboard/components/ui/WootButton.vue';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import UserAvatarWithName from 'dashboard/components/widgets/UserAvatarWithName';
import InboxIconWithName from 'dashboard/components/widgets/InboxIconWithName';
import campaignMixin from 'shared/mixins/campaignMixin';
import timeMixin from 'dashboard/mixins/time';

import moment from 'moment-timezone'
import EditOneOffCampaign from './EditOneOffCampaign.vue';
import ProgressPopUp from './ProgressPopUp.vue';
import CampaignRecordsModal from './CampaignRecordsModal.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
    EditOneOffCampaign,
    ProgressPopUp,
    CampaignRecordsModal
  },

  mixins: [clickaway, timeMixin, campaignMixin, messageFormatterMixin],

  data() {
    return {
      interval: null,
      activeInterval: null,
      openProgressPopUp: false,
      showOneOffEditPopup: false,
      openCampaignRecords: false,
      progressPayload: '',
      recordsPayload: '',
      selectedCampaign:'',
    };
  },

  props: {
    campaigns: {
      type: Array,
      default: () => [],
    },
    showEmptyResult: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.inProgressCampaignStats();
  },

  beforeDestroy() {
    if (this.interval ) {
      clearInterval(this.interval);
    }
  },

  methods: {
    openOneOffEditPopup(response) {
      const { row: campaign } = response;
      this.selectedCampaign = campaign;
      this.showOneOffEditPopup = true;
    },
    hideOneOffEditPopup() {
      this.showOneOffEditPopup = false;
    },
    hideProgressPopup() {
      this.openProgressPopUp = false;
      this.progressPayload = '';
    },
    hideRecordsPopup() {
      this.openCampaignRecords = false;
      this.recordsPayload = '';
    },
    showCampaignRecords(records) {
      this.openCampaignRecords = true;
      this.recordsPayload = records;
    },
    async inProgressCampaignStats() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(async () => {
        for (const campaign of this.allCampaigns) {
          let response = await this.$store.dispatch('contacts/get_stats', {id: campaign.campaign_id});
          const campaignIndex = this.tableData.findIndex(c => c.campaign_id === campaign.campaign_id);
          if (campaignIndex !== -1) {
            const { sent, failed, pending, total, status } = response.data.stats;
            let limit = '';
            let limitLeft = this.smsLimit - sent
            if (this.isOneOffType && sent < this.smsLimit)
            {
              limitLeft = this.smsLimit - sent
              limit = `Limit left: ${limitLeft}`
            }
            else if(this.isEmailType && sent < this.emailLimit){
              limitLeft = this.emailLimit - sent
              limit = `Limit left: ${limitLeft}`
            }
            else {
              limit = "Limit Reached"
            }


            this.$set(this.tableData, campaignIndex, {
              ...this.tableData[campaignIndex],
              sent,
              failed,
              pending,
              max: total,
              campaign_status: status,
              limit: limit
            });
          }
        }
      }, 2000);
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      appIntegrations: 'integrations/getAppIntegrations',
      getAccount: 'accounts/getAccount',
    }),
    smsLimit() {
      let account = this.getAccount(this.accountId);
      let limit = Number(account.sms_campaign_limit);
      return isNaN(limit) ? 0 : Math.floor(limit);
    },
    emailLimit() {
      let account = this.getAccount(this.accountId);
      let limit = Number(account.email_campaign_limit);
      return isNaN(limit) ? 0 : Math.floor(limit);
    },
    allCampaigns() {
      this.campaigns = this.$store.getters['campaigns/getCampaigns'](this.campaignType);
      return this.campaigns
    },
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    inboxes() {
      if (this.isOngoingType) {
        return this.$store.getters['inboxes/getWebsiteInboxes'];
      }
      return this.$store.getters['inboxes/getTwilioInboxes'];
    },
    emptyMessage() {
      if (this.isOngoingType) {
        return this.inboxes.length
          ? this.$t('CAMPAIGN.ONGOING.404')
          : this.$t('CAMPAIGN.ONGOING.INBOXES_NOT_FOUND');
      }
      else if(this.isEmailType) {
        return this.inboxes.length ?
          this.$t('CAMPAIGN.EMAIL.404')
          : this.$t('CAMPAIGN.EMAIL.INBOXES_NOT_FOUND');
      }
      else if(this.isCallingType) {
        return this.$t('CAMPAIGN.CALLING.404')
      }

      return this.inboxes.length
        ? this.$t('CAMPAIGN.ONE_OFF.404')
        : this.$t('CAMPAIGN.ONE_OFF.INBOXES_NOT_FOUND');
    },
    tableData() {
      if (this.isLoading) {
        return [];
      }
      return this.campaigns.map(item => {
        return {
          ...item,
          url: item.trigger_rules.url,
          timeOnPage: item.trigger_rules.time_on_page,
          scheduledAt: item.timezone
            ? moment(item.scheduled_at).tz(item.timezone).format('MMM DD, h:mm A')
            : moment(item.scheduled_at).local().format('MMM DD, h:mm A')
        };
      });
    },
    columns() {
      const visibleToAllTable = [
        {
          field: 'title',
          key: 'title',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.TITLE'),
          fixed: 'left',
          align: 'left',
          renderBodyCell: ({ row }) => {
            const total = row.sent + row.failed + row.pending;
            const sentWidth = total ? (row.sent / total) * 100 : 0;
            const pendingWidth = total ? (row.pending / total) * 100 : 0;
            const failedWidth = total ? (row.failed / total) * 100 : 0;
            const campaignType = this.isOneOffType ? 'one_off' : (this.isEmailType ? 'email' : '');
            const handleProgressClick = () => {
              let payload = {
                title: row.title,
                id: row.campaign_id,
                sent: row.sent,
                failed: row.failed,
                pending: row.pending,
                campaign_type: campaignType
              }
              this.progressPayload = payload;
              this.openProgressPopUp = true;
            };

            return (
              <div>
                <div class="row--title-block">
                  <h6 class="sub-block-title title text-truncate">{row.title}</h6>
                  {this.isOneOffType && total > this.smsLimit && row.pending > 0 && (
                    <p class="limit">{row.limit}</p>
                  )}
                  {this.isEmailType && total > this.emailLimit && row.pending > 0 && (
                    <p class="limit">{row.limit}</p>
                  )}
                </div>
                {(this.isOneOffType || this.isEmailType) && (
                  <div>
                    <div class="progress-container" onClick={handleProgressClick}>
                      <div class="progress-bar">
                        <div
                          class="progress-sent"
                          style={{ width: `${sentWidth}%`, backgroundColor: 'green' }}
                        >
                          {row.sent > 0 && (
                            <span class="progress-text">{row.sent}</span>
                          )}
                        </div>
                        <div
                          class="progress-pending"
                          style={{ width: `${pendingWidth}%`, backgroundColor: 'yellow' }}
                        >
                          {row.pending > 0 && (
                            <span class="progress-text" style={{ color: 'black' }}>
                      {row.pending}
                    </span>
                          )}
                        </div>
                        <div
                          class="progress-failed"
                          style={{ width: `${failedWidth}%`, backgroundColor: 'red' }}
                        >
                          {row.failed > 0 && <span class="progress-text">{row.failed}</span>}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          },
        },
        {
          field: 'message',
          key: 'message',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.MESSAGE'),
          align: 'left',
          width: 350,
          renderBodyCell: ({ row }) => {
            if (row.message) {
              return (
                <div class="text-truncate">
                  <span
                    domPropsInnerHTML={this.formatMessage(row.message)}
                  ></span>
                </div>
              );
            }
            return '';
          },
        },
        {
          field: 'inbox',
          key: 'inbox',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.INBOX'),
          align: 'left',
          renderBodyCell: ({ row }) => {
            return <InboxIconWithName inbox={row.inbox} />;
          },
        },
      ];
      if (this.isOngoingType) {
        return [
          ...visibleToAllTable,
          {
            field: 'enabled',
            key: 'enabled',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.STATUS'),
            align: 'left',
            renderBodyCell: ({ row }) => {
              const labelText = row.enabled
                ? this.$t('CAMPAIGN.LIST.STATUS.ENABLED')
                : this.$t('CAMPAIGN.LIST.STATUS.DISABLED');
              const colorScheme = row.enabled ? 'success' : 'secondary';
              return <Label title={labelText} colorScheme={colorScheme} />;
            },
          },
          {
            field: 'sender',
            key: 'sender',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.SENDER'),
            align: 'left',
            renderBodyCell: ({ row }) => {
              if (row.sender) return <UserAvatarWithName user={row.sender} />;
              return this.$t('CAMPAIGN.LIST.SENDER.BOT');
            },
          },
          {
            field: 'url',
            key: 'url',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.URL'),
            align: 'left',
            renderBodyCell: ({ row }) => (
              <div class="text-truncate">
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href={row.url}
                  title={row.url}
                >
                  {row.url}
                </a>
              </div>
            ),
          },
          {
            field: 'timeOnPage',
            key: 'timeOnPage',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.TIME_ON_PAGE'),
            align: 'left',
          },

          {
            field: 'buttons',
            key: 'buttons',
            title: '',
            align: 'left',
            renderBodyCell: row => (
              <div class="button-wrapper">
                <WootButton
                  variant="clear"
                  icon="ion-edit"
                  color-scheme="secondary"
                  classNames="grey-btn"
                  onClick={() => this.$emit('on-edit-click', row)}
                >
                  {this.$t('CAMPAIGN.LIST.BUTTONS.EDIT')}
                </WootButton>
                <WootButton
                  variant="link"
                  icon="ion-close-circled"
                  color-scheme="secondary"
                  onClick={() => this.$emit('on-delete-click', row)}
                >
                  {this.$t('CAMPAIGN.LIST.BUTTONS.DELETE')}
                </WootButton>
              </div>
            ),
          },
        ];
      }
      else if(this.isEmailType){
        return [
          ...visibleToAllTable,
          {
            field: 'campaign_status',
            key: 'campaign_status',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.STATUS'),
            align: 'left',
            renderBodyCell: ({ row }) => {
              const labelText = row.campaign_status;
              let colorScheme =
                row.campaign_status === 'paused' ? 'alert' :
                  row.campaign_status === 'inprogress' || row.campaign_status === 'active' ? 'success' :
                    row.campaign_status === 'waiting' ? 'warning' :
                      row.campaign_status === 'completed' ? 'secondary' :
                        '';

              const label = <Label title={labelText} colorScheme={colorScheme} />;
              let actionButtons = null;
              if (row.campaign_status === 'inprogress') {
                actionButtons = (
                  <button
                    onClick={() => this.$emit('pauseCampaign', row.campaign_id)}
                    class="btn-pause"
                  >
                    <i class="ion-ios-pause text-4xl text-green-500" />
                  </button>
                );
              } else if (row.campaign_status === 'paused') {
                actionButtons = (
                  <button
                    onClick={() => this.$emit('playCampaign', row.campaign_id)}
                    class="btn-play"
                  >
                    <i class="ion-ios-play text-4xl text-red-500" />
                  </button>
                );
              }
              return (
                <div class="status-container">
                  {label}
                  {actionButtons}
                </div>
              );
            },
          },
          {
            field: 'scheduledAt',
            key: 'scheduledAt',
            title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.SCHEDULED_AT'),
            align: 'left',
          },
          {
            field: 'timezone',
            key: 'timezone',
            title: 'Time-Zone',
            align: 'left',
          },
          {
            field: 'selected_type',
            key: 'selected_type',
            title: 'Type',
            align: 'left',
          },
          {
            field: 'buttons',
            key: 'buttons',
            title: '',
            align: 'left',
            renderBodyCell: row => (
              <div class="button-wrapper">
                <WootButton
                  variant="link"
                  icon="ion-close-circled"
                  color-scheme="secondary"
                  onClick={() => this.$emit('on-delete-click', row)}
                >
                  {this.$t('CAMPAIGN.LIST.BUTTONS.DELETE')}
                </WootButton>
              </div>
            ),
          },
        ];
      }
      else if(this.isCallingType){
        return [
          ...visibleToAllTable,
          {
            field: 'results',
            key: 'calling_campaign_id',
            title: "",
            align: 'left',
            renderBodyCell: row => (
              <div class="button-wrapper">
                <WootButton
                  variant="link"
                  icon="ion-ios-telephone"
                  color-scheme="green"
                  onClick={() => this.$emit('on-result-click', row)}
                >
                  Result
                </WootButton>
              </div>
            ),
          },
          {
            field: 'leads',
            key: 'leads',
            title: "",
            align: 'left',
            renderBodyCell: row => {
              if (row.row.leads) {
                return (
                  <div class="button-wrapper">
                    <WootButton
                      variant="link"
                      icon="ion-person"
                      color-scheme="green"
                      onClick={() => this.$emit('on-leads-click', row)}
                    >
                      Leads
                    </WootButton>
                  </div>
                );
              }
              return null;
            },
          },
          {
            field: 'selected_type',
            key: 'selected_type',
            title: 'Type',
            align: 'left',
          },
          {
            field: 'buttons',
            key: 'buttons',
            title: '',
            align: 'left',
            renderBodyCell: row => (
              <div class="button-wrapper">
                <WootButton
                  variant="link"
                  icon="ion-close-circled"
                  color-scheme="secondary"
                  onClick={() => this.$emit('on-calling-delete-click', row)}
                >
                  {this.$t('CAMPAIGN.LIST.BUTTONS.DELETE')}
                </WootButton>
              </div>
            ),
          },
        ];
      }
      return [
        ...visibleToAllTable,
        {
          field: 'campaign_status',
          key: 'campaign_status',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.STATUS'),
          align: 'left',
          renderBodyCell: ({ row }) => {
            const labelText = row.campaign_status;
            let colorScheme =
              row.campaign_status === 'paused' ? 'alert' :
                row.campaign_status === 'inprogress' || row.campaign_status === 'active' ? 'success' :
                  row.campaign_status === 'waiting' ? 'warning' :
                    row.campaign_status === 'completed' ? 'secondary' :
                      '';

            const label = <Label title={labelText} colorScheme={colorScheme} />;
            let actionButtons = null;
            if (row.campaign_status === 'inprogress') {
              actionButtons = (
                <button
                  onClick={() => this.$emit('pauseCampaign', row.campaign_id)}
                  class="btn-pause"
                >
                  <i class="ion-ios-pause text-4xl text-green-500" />
                </button>
              );
            } else if (row.campaign_status === 'paused') {
              actionButtons = (
                <button
                  onClick={() => this.$emit('playCampaign', row.campaign_id)}
                  class="btn-play"
                >
                  <i class="ion-ios-play text-4xl text-red-500" />
                </button>
              );
            }
            return (
              <div class="status-container">
                {label}
                {actionButtons}
              </div>
            );
          },
        },
        {
          field: 'scheduledAt',
          key: 'scheduledAt',
          title: this.$t('CAMPAIGN.LIST.TABLE_HEADER.SCHEDULED_AT'),
          align: 'left',
        },
        {
          field: 'timezone',
          key: 'timezone',
          title: 'Time-Zone',
          align: 'left',
        },
        {
          field: 'buttons',
          key: 'buttons',
          title: '',
          align: 'left',
          renderBodyCell: row => {
            return (
              <div class="button-wrapper">
                {(row.row.campaign_status == 'completed' || row.row.campaign_status == 'active') && (
                  <WootButton
                    variant="link"
                    icon="ion-close-circled"
                    color-scheme="secondary"
                    onClick={() => this.$emit('on-delete-click', row)}
                  >
                    {this.$t('CAMPAIGN.LIST.BUTTONS.DELETE')}
                  </WootButton>
                )}
                {(row.row.campaign_status == 'waiting' || row.row.campaign_status == 'paused' || row.row.campaign_status == 'active') && (
                  <WootButton
                    variant="clear"
                    icon="ion-edit"
                    color-scheme="secondary"
                    classNames="grey-btn"
                    onClick={() => this.openOneOffEditPopup(row)}
                  >
                    {this.$t('CAMPAIGN.LIST.BUTTONS.EDIT')}
                  </WootButton>
                )}
              </div>
            );
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-table-wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
    thead.ve-table-header .ve-table-header-tr .ve-table-header-th {
      font-size: var(--font-size-mini);
      padding: var(--space-small) var(--space-two);
    }
    tbody.ve-table-body .ve-table-body-tr .ve-table-body-td {
      padding: var(--space-slab) var(--space-two);
    }
  }

  .row--title-block {
    align-items: center;
    display: flex;
    text-align: left;
    position: relative;
    .title {
      font-size: var(--font-size-small);
      margin: 0;
      text-transform: capitalize; }
    .limit {
      font-size: var(--font-size-small);
      margin-left: auto;
      white-space: nowrap; }
  }
  .label {
    padding: var(--space-smaller) var(--space-small);
  }
}

.campaign--loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-big);
}

.button-wrapper {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  min-width: 20rem;
}

.progress-container {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-top: 10px;
  position: relative;
}

.progress-bar {
  display: flex;
  height: 13px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}
.progress-sent,
.progress-pending,
.progress-failed {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-text {
  position: absolute;
  color: white;
  font-size: 12px;
  font-weight: bold;
  z-index: 2;
  text-align: center;
}
.status {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 12px;
  color: #555;
}
.status span {
  font-weight: bold;
}

.status-container {
  display: flex;
  align-items: center;
  gap: 10px;
}


.btn-pause, .btn-play {
  background-color: transparent;
  border: none;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-pause:hover,
.btn-play:hover {
  opacity: 0.8;
}

.status-container .label {
  font-weight: bold;
  text-transform: capitalize;
}

</style>
